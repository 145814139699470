<template>
  <view-container name="settings">
    <view-header>
      <custom-header :router-back="'/account'">
        <template v-slot:default>
          <svg-icon size="size24x24" :name="'icoArrowLeft'" />Settings
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div data-vue-name="Settings" class="settings">
        <ul class="settings__nav">
          <li v-for="item in settingsNav" :key="item.id">
            <router-link :to="item.url">
              <svg-icon size="size24x24" class-name="me-3" :name="item.img" />
              <span>
                {{ item.title }}
              </span>
              <svg-icon size="size20x20" class-name="ms-auto" :name="'icoArrowRightBlue'" />
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="togglePopupSignout">
              <svg class="me-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 18H6V20H18V4H6V6H4V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V18ZM6 11H13V13H6V16L1 12L6 8V11Z"
                  fill="#7B8B97" />
              </svg>
              <span>Sign Out</span></a>
          </li>
        </ul>
      </div>
      <Popup :open="isShowPopupExit" @close="togglePopupSignout">
        <h4> {{ $t('popup.signout.title') }} </h4>

        <template slot="actions">
          <a-button @click="logout">
            <Icon :size="20" type="check" />

            {{ $t('popup.signout.yes') }}
          </a-button>

          <a-button class="secondary" @click="togglePopupSignout">
            {{ $t('popup.signout.no') }}
          </a-button>
        </template>
      </Popup>
    </view-content>
  </view-container>
</template>

<script>
import Popup from '@/components/Popup'
import Icon from '@/components/Icon.vue'
export default {
  name: 'Settings',
  components: {
    Popup,
    Icon
  },
  data() {
    return {
      isShowPopupExit: false,
      settingsNav: [
        {
          id: 1,
          title: 'Personal info',
          url: '/personal-info',
          img: 'icoAccount',
        },
        {
          id: 2,
          title: 'Physiological Data',
          url: '/physiological-data',
          img: 'icoOpenArm',
        },
        // { id: 3, title: 'System Settings', url: 'def', img: 'icoEqualizer' },
        // {
        //   id: 4,
        //   title: 'Notifications & Alerts',
        //   url: 'def',
        //   img: 'icoNotification',
        // },
        {
          id: 5,
          title: 'Security & Confidentiality',
          url: '/settings/security',
          img: 'icoShieldUser',
        },
        { id: 6, title: 'Info', url: '/settings/info', img: 'icoInformation' },
        { id: 7, title: 'Feedback', url: '/settings/feedback', img: 'icoFeedback' },
      ],
    }
  },
  methods: {
    togglePopupSignout() {
      this.isShowPopupExit = !this.isShowPopupExit
    },
    logout() {
      console.log('logout')
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'authorization' })
      })
    },
  },
}
</script>

<style lang="stylus">

.settings__nav li a {
  display flex
  align-items center
  padding 25px 0
  font-size: 16px;
  line-height: 140%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: #D8D8D8;
  text-decoration none

  .size20x20 {
    margin-right 18px
  }
}
.settings__nav li:last-child a {
  border none
}

#app .view.settings-view .view-content {
  padding 0 16px
}
</style>
